<template>
  <v-app class="assinatura">
    <v-main fill-height>
      <v-container fluid>
        <v-layout row wrap>
          <v-flex xs12 md4 pa-2>
            <v-stepper v-model="e1">
              <v-stepper-header>
                <v-stepper-step color="#00853B" :complete="e1 > 1" editable step="1"> Passo 1 </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step color="#00853B" :complete="e1 > 2" editable step="2"> Passo 2</v-stepper-step>
              </v-stepper-header>

              <v-stepper-items>
                <v-stepper-content step="1">
                  <v-card class="formulario">
                    <v-card-text>
                      <v-form>
                        <v-text-field
                        ref="start"
                        tabindex="1"
                        v-model="nome"
                        label="Nome"
                        color="#00853B"
                        autocomplete="novo-nome"
                        type="text"
                        ></v-text-field>
                        <v-text-field
                        tabindex="2"
                        v-model="funcao"
                        label="Função"
                        color="#00853B"
                        autocomplete="nova-funcao"
                        type="text"
                        ></v-text-field>
                        <v-text-field
                        tabindex="3"
                        v-model="setor"
                        label="Setor"
                        color="#00853B"
                        autocomplete="novo-email"
                        type="text"
                        ></v-text-field>
                        <v-text-field
                        tabindex="4"
                        v-model="telefone"
                        v-mask="'(##) ####.####'"
                        label="Telefone"
                        color="#00853B"
                        autocomplete="novo-telefone"
                        type="text"
                        ></v-text-field>
                        <v-text-field
                        tabindex="5"
                        v-model="email"
                        label="E-mail"
                        color="#00853B"
                        autocomplete="novo-email"
                        type="text"
                        ></v-text-field>
                      </v-form>
                    </v-card-text>
                    <v-btn color="#00853B" @click="e1 = 2" > Avançar </v-btn>
                  </v-card>
                </v-stepper-content>
                <v-stepper-content step="2">
                  <v-card class="formulario">
                    <v-card-text>
                      <v-form>
                        <v-text-field 
                        tabindex="6"
                        v-model="logradouro"
                        label="Endereço"
                        color="#00853B"
                        autocomplete="novo-endereco"
                        type="text"
                        ></v-text-field>
                        <v-text-field
                        tabindex="7"
                        v-model="numero"
                        label="Nº"
                        color="#00853B"
                        autocomplete="novo-numero"
                        type="text"
                        ></v-text-field>
                        <v-text-field
                        tabindex="8"
                        v-model="bairro"
                        label="Bairro"
                        color="#00853B"
                        autocomplete="novo-bairro"
                        type="text"
                        ></v-text-field>
                        <v-text-field
                        tabindex="9"
                        v-model="cep"
                        v-mask="'##.###-###'"
                        label="CEP"
                        color="#00853B"
                        autocomplete="novo-cep"
                        type="text"
                        ></v-text-field>
                        <v-text-field
                        tabindex="10"
                        v-model="municipio"
                        label="Município"
                        color="#00853B"
                        autocomplete="novo-municipio"
                        type="text"
                        ></v-text-field>
                        <v-text-field
                        tabindex="11"
                        v-model="telefone2"
                        v-mask="'(##) ####.####'"
                        label="Telefone"
                        color="#00853B"
                        autocomplete="novo-telefone2"
                        type="text"
                        ></v-text-field>
                      </v-form>
                    </v-card-text>
                    <v-btn
                    ref="copy"
                    class="js-copy"
                    color="#00853B"
                    @click.native.prevent
                    data-clipboard-target="#source">
                    Copiar
                    </v-btn>
                  </v-card>
                 
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-flex>
          <v-flex xs12 md8 pa-2>
            <!-- AREA DE COPIAR -->
            <v-card class="formulario" height="100%">
              <div class="assinatura_source">
                <div ref="sourceWrapper">
                  <table 
                  id="source"
                  cellpadding="0" 
                  cellspacing="0" 
                  class="tuece"
                  v-if="layoutSelected == 'padrao'"
                  >
                  <tr>
                    <th width="190px;" class="tuece-style">
                      <span style="font-family: Roboto; font-size: 10pt; font-weight: 600;">
                        {{ nome }}
                        </span> <br />
                      <span
                      style="
                      font-family: Roboto;
                      font-size: 9pt; 
                      ">
                      {{ funcao }}</span><br />
                      <span
                      style="
                      font-family: Roboto;
                      font-size: 8pt; 
                      ">
                      {{ setor }}</span><br />
                      <span style="font-family: Roboto; font-size: 8pt;">Fone: {{ telefone || "(85) 3101.9718" }} <br />E-mail: {{ email }}</span><br />
                      <!-- <span style="font-family: Soleto-Regular; font-size: 7px;"></span> -->
                    </th>
                    <th 
                    style="padding-left: 20px; right"
                    class="tuece-style"
                    rowspan=""
                    >
                    <img src="assets/img/uece.png" alt="Logo UECE" width="140px;" align="right" /><br /><br /><br />
                          
                    <p style="font-family: Roboto; font-size: 7pt; text-align: right">
                    {{ logradouro || "Av. Silas Munguba" }}, {{ numero || "1700" }} - {{ bairro || "Itaperi"}}<br />
                    Cep: {{ cep || "60.714-903" }} - {{ municipio || "Fortaleza"}}/CE<br />
                    Fone: {{ telefone2 || "(85) 3101.9600"}}</p>
                    </th>
                    <th class="linha-vertical">
                    </th>
                    <th 
                    style="padding-left: 20px; right"
                    class="tuece-style"
                    rowspan=""
                    >
                    <div>
                    <!-- <img src="@/assets/img/ceara.png" alt="Logo Ceara" height="50px;"  /><br /><br /><br /> -->
                    <img src="assets/img/ceara.png" alt="Logo Ceara" width="120px;"  /><br /><br /><br />
                    <a href="https://facebook.com/ueceoficial"><img src="assets/img/facebook.png" height="13px;" /></a>    
                    <a href="https://instagram.com/ueceoficial"><img src="assets/img/insta.png" height="13px;" /></a>
                    <a href="https://youtube.com/ueceoficial"><img src="assets/img/youtube.png" height="13px;" /></a>
                    </div>
                    </th>
                  </tr>
                  <!-- <tr valign="bottom">
                    <td style="color:#616161">
                      <v-icon alt="Telefone" style="font-size: 7px;">mdi-phone</v-icon> {{ telefone || " (85) 3101-9718" }} <br />
                    </td>
                  </tr> -->
                  <!-- <tr class="tuece-style" style="font-family: Soleto-Regular; font-size: 7px;">
                    {{ email }}
                  </tr> -->
                  <tr>
                    <th style="padding-top: 20px;" colspan="5">
                    <img src="assets/img/rodape.png" width="540px"/>
                      
                     
                    </th>
                  </tr>
                  </table>
                </div>
              </div>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
    <v-snackbar :timeout="1000" v-model="feedback" color="success">
              Copiado!
    </v-snackbar>
  </v-app>
</template>

<script>
  import Clipboard from "clipboard";

export default {
  data: () => ({
    feedback: false,
    nome: "",
    funcao: "",
    telefone:"",
    email: "",
    setor: "",
    logradouro: "",
    numero: "",
    bairro: "",
    cep: "",
    municipio: "",
    ddd: "",
    telefone2: "",
    e1: 1,

    rules: {
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "E-mail inválido.";
      }
    },
    logos: [
      {
        text: "Logo UECE",
        value: "assets/img/uece.png"
      },
      {
        text: "Logo Ceara",
        value: "assets/img/ceara.png"
      }
      ],
    layout: [
      {
        text: "Layout padrão",
        value: "padrao"
      }
    ],
    layoutSelected: "padrao",
    logoSelected: "assets/img/uece.png"
    
  }),
  computed: {
    tel1() {
      return `tel:${this.telefone.replace(/ /g, "").replace(/\D/g, "")}`;
    }
  },
  mounted() {
    this.$refs.start.focus();

    const assinaturaClipboard = new Clipboard(".js-copy");

    const assinaturaHTMLClipboard = new Clipboard(".js-copy-src", {
      text: () => {
        return this.$refs.sourceWrapper.innerHTML;
      }
    });

    assinaturaClipboard.on("success", () => {
      this.feedback = true;
    });

    assinaturaHTMLClipboard.on("success", () => {
      this.feedback = true;
    });

  },
  props: {
    source: String
  }

}
</script>

<style lang="scss">
$uece: #00853B;
$primary: $uece;
$copy: #2a3844;
$secondary: #779ec3;
$third: #665ef1;
$purple: $third;
// $blue: $primary;
$black: $copy;

html {
  height: 100%;
  box-sizing: border-box;

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
}

body {
  margin: 0;
  padding: 0;
  background: #fafafa;
  color: $copy;
}

.assinatura {
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;

  &_header {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: flex-end;
    margin-top: 40px;

    span {
      color: $copy;
      font-family: Soleto-Medium, sans-serif;
      
      
    }
  }
  .logo {
    margin-bottom: -27px; 
  }

  &_source {
    overflow: hidden;
    border: 2px dashed $primary;
    height: 100%;
    display: flex;
    position: relative;
    > div {
      margin: auto;
      min-width: 400px;
    }
  }

  &_step {
    display: flex;

    img {
      width: 100%;
      height: auto;
      margin: auto;
      padding: 4px 4px 0;
    }
  }
  .linha-vertical {
    height: 90px;
    border-right: 1px solid black;
  }
  .linha-horizontal {
    height: 100px;
    width: 1000px;
    background-image: url(../assets/img/rodape.png);
  }

  .tuece {
    border-collapse: collapse;
    border-spacing: 0;
    padding-top: 20px;
  }

  .tuece td {
    font-family: Roboto, sans-serif;
    font-size: 7px;
    padding: 0 9px 0 0;
    overflow: hidden;
    word-break: normal;
    font-weight: 500;
  }

  .tuece th {
    font-family: Roboto, sans-serif;
    font-size: 12px;
    font-weight: 600;
    overflow: hidden;
    word-break: normal;
  }
  .tuece th span {
    font-weight: 500;
  }

  .tuece th p {
    font-weight: 500;
  }

  .tuece .tuece-style {
    text-align: left;
    padding-right: 20px;
    padding-left: 10px;    
  }

  img {
    margin-right: 0px;
  }
  i.v-icon.material-icons {
    display: none;
  }
  a {
    text-decoration: none;
  }

  
}

</style>
